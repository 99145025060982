import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
const CsrPolicy = ({ location }) => {
    return (
        <Layout>
            <Seo
                title={"CSR Policy"}
                description={
                    "We are committed to operate our business in a socially responsible way. We believe in co-building a world and driving success with diversity, inclusion & equality"
                }
                url={location.href}
            />
            <div className="container container--py csr-policy">
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    1. Corporate Social Responsibility (CSR) Policy of Sedin Technologies
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    At Sedin, we recognize that every milestone we achieve has been reached through
                    the effort and dedication of our entire team of Sedinites. Whilst we celebrate
                    our work and wins, we understand that it is crucial to give back to the
                    community and society that has fostered our knowledge and skills, and help Sedin
                    progress towards our goal. Not only through our technological ventures and
                    innovation, we also actively look to use our resources and influence to maintain
                    the sustainability and stability of our surroundings
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Enactment of Companies Act, 2013 by the Ministry of Corporate Affairs,
                    Government of India, has brought about statutory obligations on companies to
                    take up various Corporate Social Responsibility (CSR) projects towards social
                    causes and welfare activities.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Sedin Technologies (‘Sedin’ or the ‘Company’) looks to proudly adopt such
                    practices into our ecosystem and undertake various initiatives to tangibly and
                    positively impact our society and the environment. As a company firmly rooted in
                    ethical practices and beliefs, we extend our values towards the larger community
                    and circle of people.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">2. Objectives</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The objectives of Sedin’s CSR Policy are:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        To lay down comprehensive guidelines and mottos that will be adopted by the
                        Company to fulfil its CSR obligations as monitored by the CSR Board
                    </li>
                    <li>
                        To provide layout for various activities/initiatives that will be undertaken
                        by the Company in the areas of poverty reduction, hunger and malnutrition
                        eradication, animal welfare, education, healthcare, infrastructure, culture
                        and overall social improvement of disadvantaged groups.
                    </li>
                    <li>
                        To solidify and put to practice the aforementioned actions by the Company
                        for societal and environmental preservation.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    2. CSR Activities and Focus Areas
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Company is to undertake projects which fall under any of these focus areas
                    as specified by the Schedule VII of the Act. All such initiatives will be
                    undertaken in accordance with the activities specified in Schedule VII of the
                    Act, read with Section 135 of the Act.
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        Eradicating hunger, poverty and malnutrition, [“promoting health care
                        including preventive health care”] and sanitation [including contribution to
                        the Swach Bharat Kosh set-up by the Central Government for the promotion of
                        sanitation] and making available safe drinking water.
                    </li>
                    <li>
                        Promoting education, including special education and employment enhancing
                        vocational skills especially among children, women, elderly and the
                        differently abled and livelihood enhancement projects.
                    </li>
                    <li>
                        Promoting gender equality, empowering women, setting up homes and hostels
                        for women and orphans; setting up old age homes, day care centers and such
                        other facilities for senior citizens and measures for reducing inequalities
                        faced by socially and economically backward groups.
                    </li>
                    <li>
                        Ensuring environmental sustainability, ecological balance, protection of
                        flora and fauna, animal welfare, agroforestry, conservation of natural
                        resources and maintaining quality of soil, air and water including
                        contribution to the Clean Ganga Fund set-up by the Central Government for
                        rejuvenation of river Ganga.
                    </li>
                    <li>
                        Protection of national heritage, art and culture including restoration of
                        buildings and sites of historical importance and works of art; setting up
                        public libraries; promotion and development of traditional art and
                        handicrafts;
                    </li>
                    <li>
                        Measures for the benefit of armed forces veterans, war widows and their
                        dependents, [ Central Armed Police Forces (CAPF) and Central Para Military
                        Forces (CPMF) veterans, and their dependents including widows];
                    </li>
                    <li>
                        Training to promote rural sports, nationally recognised sports, paralympic
                        sports and olympic sports.
                    </li>
                    <li>
                        Contribution to the prime minister’s national relief fund 8[or Prime
                        Minister’s Citizen Assistance and Relief in Emergency Situations Fund (PM
                        CARES Fund)] or any other fund set up by the central govt. for socio
                        economic development and relief and welfare of the schedule caste, tribes,
                        other backward classes, minorities and women;
                    </li>
                    <li>
                        <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                            <li>
                                Contribution to incubators or research and development projects in
                                the field of science, technology, engineering and medicine, funded
                                by the Central Government or State Government or Public Sector
                                Undertaking or any agency of the Central Government or State
                                Government; and
                            </li>
                            <li>
                                Contributions to public funded Universities; Indian Institute of
                                Technology (IITs); National Laboratories and autonomous bodies
                                established under Department of Atomic Energy (DAE); Department of
                                Biotechnology (DBT); Department of Science and Technology (DST);
                                Department of Pharmaceuticals; Ministry of Ayurveda, Yoga and
                                Naturopathy, Unani, Siddha and Homeopathy (AYUSH); Ministry of
                                Electronics and Information Technology and other bodies, namely
                                Defense Research and Development Organisation (DRDO); Indian Council
                                of Agricultural Research (ICAR); Indian Council of Medical Research
                                (ICMR) and Council of Scientific and Industrial Research (CSIR),
                                engaged in conducting research in science, technology, engineering
                                and medicine aimed at promoting Sustainable Development Goals
                                (SDGs).
                            </li>
                        </ul>
                    </li>
                    <li>Rural development projects.</li>
                    <li>Slum area development.</li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Explanation.- For the purposes of this item, the term `slum area' shall mean any
                    area declared as such by the Central Government or any State Government or any
                    other competent authority under any law for the time being in force.
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        Disaster management, including relief, rehabilitation and reconstruction
                        activities.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">4. CSR Committee</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    As per the provisions of Section 135 of the Act, the Board of Directors of the
                    Company has vested the responsibility of overseeing the CSR implementation to
                    the CSR Committee (‘Committee’). The Committee will be held responsible for all
                    decisions taken with respect to the Company’s CSR obligations:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        <a href="mailto:dinesh@railsfactory.com">Dinesh V V</a>
                    </li>
                    <li>
                        <a href="mailto:Vimala@sedintechnologies">Vimala R</a>
                    </li>
                    <li>
                        <a href="mailto: jagadeesan@sedintechnologies.com"> Jagadeesan V</a>
                    </li>
                    <li>
                        <a href="mailto:mani@sedintechnologies.com"> Manikandaraman A</a>
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    5 .Responsibilities of CSR Committee
                </h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        Formulate and recommend to the Board, a Corporate Social Responsibility
                        Policy which shall indicate the activities to be undertaken by the Company
                        in areas or subject, specified in Schedule VII
                    </li>
                    <li>
                        Recommend the amount of expenditure to be incurred on the activities
                        referred to in clause (a); and
                    </li>
                    <li>
                        Monitor the Corporate Social Responsibility Policy of the company from time
                        to time.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    6. CSR Expenditure
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Board of the Company shall ensure that the company spends, in every
                    financial year, at least two per cent of the average net profits of the company
                    made during the three immediately preceding financial years.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Provided that the company shall give preference to the local area and areas
                    around it where it operates, for spending the amount earmarked for Corporate
                    Social Responsibility activities:
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Provided further that if the company fails to spend such amount, the Board
                    shall, in its report made under clause (o) of sub-section (3) of section 134,
                    specify the reasons for not spending the amount and, unless the unspent amount
                    relates to any ongoing project, transfer such unspent amount to a Fund specified
                    in Schedule VII, within a period of six months of the expiry of the financial
                    year.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Provided also that if the company spends an amount in excess of the requirements
                    provided under this subsection, such company may set off such excess amount
                    against the requirement to spend under this subsection for such number of
                    succeeding financial years and in such manner, as may be prescribed
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Any amount remaining unspent, pursuant to any ongoing project, fulfilling such
                    conditions as may be prescribed, undertaken by a company in pursuance of its
                    Corporate Social Responsibility Policy, shall be transferred by the company
                    within a period of thirty days from the end of the financial year to a special
                    account to be opened by the company in that behalf for that financial year in
                    any scheduled bank to be called the Unspent Corporate Social Responsibility
                    Account, and such amount shall be spent by the company in pursuance of its
                    obligation towards the Corporate Social Responsibility Policy within a period of
                    three financial years from the date of such transfer, failing which, the company
                    shall transfer the same to a Fund specified in Schedule VII, within a period of
                    thirty days from the date of completion of the third financial year.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    7. Disclosure of CSR
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    As per CSR obligations, the contents of the CSR Policy shall be included in the
                    Directors’ Report and the same shall be displayed on the Company’s website if
                    any. The Committee will submit an Annual Report for review of the Board in
                    accordance with the rules of the Companies Act, 2013.
                </p>
            </div>
        </Layout>
    );
};

export default CsrPolicy;
